// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const generateQr = createAsyncThunk('appUsers/generateQr', async (user, { dispatch, getState }) => {
  const response = await axios.post('https://apiv3.opentlv.com/api/v3/generateqr', user)
 // await dispatch(getData(getState().users.params))
  //await dispatch(getAllData())
  return response.data
})

export const test2fa = createAsyncThunk('appUsers/test2fa', async (user, { dispatch, getState }) => {
  const response =  await axios.post('https://apiv3.opentlv.com/api/v3/test2fa', user)
  return response.data

})
export const verify2fa = createAsyncThunk('appUsers/verify2fa', async (user, { dispatch, getState }) => {
  const response =  await axios.post('https://apiv3.opentlv.com/api/v1/users/verify2fa', user)
  return response.data

})
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    secret : null
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(verify2fa.fulfilled, (state, action) => {
      state.selectedUser = action.payload
    }) 
    .addCase(generateQr.fulfilled, (state, action) => {
      state.secret = action.payload
    })

  }
})

export default appUsersSlice.reducer
