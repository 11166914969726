// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getLogSiteCamera = createAsyncThunk('appUsers/getLogSiteCamera', async params  => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/cameras/site/logs', { params })
  
  return response.data

})
export const getAllData = createAsyncThunk('appUsers/getAllData', async id  => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/cameras/all', { params: { id} })
  return response.data
})

export const getAllDataCamera = createAsyncThunk('appUsers/getAllDataCamera', async id  => {
    const response = await axios.get('https://apiv3.opentlv.com/api/v3/camera', { params: { id} })
    
    return response.data

  })
  
  export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.get('https://apiv3.opentlv.com/api/v3/cameras/data', {params})
  
    return {
      params,
      data: response.data.data,
      totalPages: response.data.total
    }
  })
  export const addGare = createAsyncThunk('appUsers/addGare', async (gare, { dispatch, getState }) => {

    await axios.post('https://apiv3.opentlv.com/sncf/addgare', gare)
  
    await dispatch(getDataChannelsClient(getState().sitesClient.params))
    return gare
  })
  
  
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    camera: [],
    logs : [],
    site : []

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataCamera.fulfilled, (state, action) => {
        state.camera = action.payload
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })

      .addCase(getLogSiteCamera.fulfilled, (state, action) => {
        state.logs = action.payload.logs
        state.site =  action.payload.site
        state.vacances = action.payload.vacances
      })

      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
